<template>
    <div class="wrapper">
      <div class="div" v-if="!rovoke">
        <b-form-group label="Nome da Integração" label-for="codigo">
          <b-form-input
            id="codigo"
            placeholder="Digite o nome da Integração"
            v-model="codigo"
            type="text"
            name="codigo"
            v-validate="'required'"
          ></b-form-input>
          <b-form-invalid-feedback :state="!errors.has('codigo')">
            Por favor, insira um nome.
          </b-form-invalid-feedback>
        </b-form-group>
        <BaseButton @click="finallyIntegration" variant="primary"> Adicionar Integração </BaseButton>
      </div>
    </div>
</template>

<script>
import ConnectionsService from "@/services/resources/ConnectionsService";
const service = ConnectionsService.build();

export default {
  name: "MelhorEnvio",
  data(){
    return{
      codigo: '',
      success: true,
      rovoke: localStorage.getItem('revoke')
    }
  },
  mounted(){
    this.finallyIntegration();
  },
  methods: {
    finallyIntegration(){
      if(this.$route.query.code){
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.success = true;
            this.loading = true;
            let data = {
              id: '/melhor_envio',
              code: this.$route.query.code,
              title: this.codigo
            }
            if(localStorage.getItem('revoke')){
              data.connection_id = localStorage.getItem('revoke');
            }

            service
            .postID(data)
            .then((e) => {
              console.log(e);
              localStorage.removeItem('revoke');
              window.close();
            })
            .catch((err) => {
              console.log(err);
              this.success = false;
            })
            .finally(() => {
              this.loading = false;
            });
          }
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.wrapper{
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .div{
    width: 80%;
    button{
      float: right;
    }
  }
}
</style>
